document.addEventListener('DOMContentLoaded', function() {

    document.addEventListener('featuredProductsLoaded', function() {
        initializeCarousel();
        initializeNavigation();
        initializeProductFilters();
        initializeProductSlider();
        initializeBackToTop();
    });

    function initializeCarousel() {
        const slides = document.getElementsByClassName("carousel-item");
        const dots = document.getElementsByClassName("dot");
        if (slides.length === 0 || dots.length === 0) {
            console.warn('Carousel elements not found');
            return;
        }

        let slideIndex = 0;
        showSlide(slideIndex);

        function showSlide(n) {
            slideIndex = n >= slides.length ? 0 : n < 0 ? slides.length - 1 : n;
            Array.from(slides).forEach((slide, i) => {
                slide.classList.toggle('active', i === slideIndex);
                dots[i].classList.toggle('active', i === slideIndex);
            });
        }

        function nextSlide() {
            showSlide(slideIndex + 1);
        }

        setInterval(nextSlide, 6000);
    }

    function initializeBackToTop() {
        const backToTopButton = document.getElementById('backToTop');
        
        window.addEventListener('scroll', () => {
          if (window.pageYOffset > 250) {
            backToTopButton.classList.add('isVisible');
          } else {
            backToTopButton.classList.remove('isVisible');
          }
        });
      
        backToTopButton.addEventListener('click', () => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        });
      }

    function initializeNavigation() {
        const navToggle = document.getElementById('nav-toggle');
        const body = document.body;
        const navLinks = document.querySelector('.nav-links');

        if (navToggle && navLinks) {
            navToggle.addEventListener('change', function() {
                body.classList.toggle('no-scroll', this.checked);
            });

            window.addEventListener('resize', function() {
                if (window.innerWidth >= 796 && navToggle.checked) {
                    navToggle.checked = false;
                    body.classList.remove('no-scroll');
                }
                checkNav();
            });

            function checkNav() {
                const shouldShowToggle = navLinks.scrollWidth > navLinks.offsetWidth;
                navToggle.style.display = shouldShowToggle ? 'block' : 'none';
                if (!shouldShowToggle) navLinks.classList.remove('open');
            }

            checkNav();
        }
    }

    function initializeProductFilters() {
        const filterSelects = document.querySelectorAll('select[name="grip"], select[name="capteur"]');
        const resetButton = document.getElementById('resetFilters');

        if (filterSelects.length && resetButton) {
            filterSelects.forEach(select => {
                select.addEventListener('change', filterProducts);
            });

            resetButton.addEventListener('click', function() {
                filterSelects.forEach(select => select.value = '');
                filterProducts();
            });
        }

        function filterProducts() {
            const products = document.querySelectorAll('.product');
            const gripFilter = document.querySelector('select[name="grip"]')?.value;
            const capteurFilter = document.querySelector('select[name="capteur"]')?.value;

            products.forEach(product => {
                const showProduct = (!gripFilter || product.dataset.grip.includes(gripFilter)) &&
                                    (!capteurFilter || product.dataset.capteur.includes(capteurFilter));
                product.style.display = showProduct ? 'block' : 'none';
            });
        }
    }

    function initializeProductSlider() {
        const container = document.querySelector('.products-container');
        const leftArrow = document.querySelector('.left-arrow');
        const rightArrow = document.querySelector('.right-arrow');

        if (!container || !leftArrow || !rightArrow) {
            return;
        }

        let scrollPosition = 0;

        function updateArrowVisibility() {
            leftArrow.style.display = scrollPosition > 0 ? 'block' : 'none';
            rightArrow.style.display = scrollPosition < container.scrollWidth - container.clientWidth ? 'block' : 'none';
        }

        leftArrow.addEventListener('click', () => {
            scrollPosition = Math.max(scrollPosition - container.clientWidth, 0);
            container.style.transform = `translateX(-${scrollPosition}px)`;
            updateArrowVisibility();
        });

        rightArrow.addEventListener('click', () => {
            scrollPosition = Math.min(scrollPosition + container.clientWidth, container.scrollWidth - container.clientWidth);
            container.style.transform = `translateX(-${scrollPosition}px)`;
            updateArrowVisibility();
        });

        updateArrowVisibility();
        window.addEventListener('resize', updateArrowVisibility);
    }
});
